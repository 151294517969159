<template>
  <div v-frag>
    <v-card-subtitle v-if='!isGeneralSettings' class='pa-0 title'>Section {{ section.sectionName }}</v-card-subtitle>
    <v-card-text v-else class='pa-0'> {{ section.sectionName }}:</v-card-text>
    <div class='mt-3 d-flex'>
      <v-text-field
        v-if="section.type === 'text'"
        :clearable='!focused && inputEditId !== section.id || active'
        :readonly='inputEditId !== section.id'
        :rules='isRuleRequired'
        :value='section.content'
        class='mr-2'
        label='Title'
        outlined
        @blur='onBlur(-1)'
        @change="$emit('saveTitle', $event, section.id)"
        @click:clear='onClear(section.id)'
      ></v-text-field>
      <v-textarea
        v-else-if="section.type === 'textarea'"
        :clearable='!focused && inputEditId !== section.id || active'
        :readonly='inputEditId !== section.id'
        :rules='isRuleRequired'
        :value='section.content'
        label='Text'
        no-resize
        rows="4"
        class='mr-2'
        outlined
        @blur='onBlur(-1)'
        @change="$emit('saveTitle', $event, section.id)"
        @click:clear='onClear(section.id)'
      ></v-textarea>
      <v-file-input
        v-else
        v-model='inputImg'
        :clearable='inputEditId !== section.id'
        :disabled='isImgDisabled'
        :label='section.content'
        :rules='imageRules'
        accept='image/png, image/jpeg, image/gif'
        class='mr-2'
        hint='Image should be less than 5MB'
        outlined
        persistent-hint
        prepend-icon=''
        show-size
        @blur='changeEditId(-1)'
        @change='onImgChange'
        @click='clearInputImg'
        @click:clear='changeEditId(section.id)'
      ></v-file-input>
      <v-tooltip top>
        <template #activator='{ on, attrs }'>
          <v-btn
            :class="{
              'primary': inputEditId !== section.id,
              'success': inputEditId === section.id
            }"
            class='ma-0 mr-2 mt-3 pa-0 primary white--text rounded-sm'
            dense
            min-height='35'
            min-width='35'
            v-bind='attrs'
            @click='changeEditId(section.id)'
            v-on='on'
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Click to Edit</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageSectionTitle',
  props: {
    section: {
      type: Object,
      required: true
    },
    isGeneralSettings: {
      type: Boolean,
      required: true
    },
    inputEditId: {
      type: Number,
      required: true,
      default: -1
    },
    focused: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    emptyRule: [
      value => !!value || 'Field is required.'
    ],
    imageRules: [
      value => !value || value.size < 5000000 || 'Image should be less than 5MB'
    ],
    isImgDisabled: true,
    inputImg: null
  }),
  computed: {
    isRuleRequired() {
      if (this.section.id === 15 || this.section.id === 16) return []
      else return this.emptyRule
    }
  },
  methods: {
    onBlur() {
      this.$emit('activeChanged', false)
      this.$emit('focusChanged', false)
      this.changeEditId(-1)
      this.isImgDisabled = true
    },
    onClear() {
      this.$emit('focusChanged', true)
      this.changeEditId(this.section.id)
    },
    changeEditId(sectionId) {
      if (sectionId === 14 || sectionId === 26) {
        this.isImgDisabled = false
      }
      if (sectionId !== 14 && sectionId !== 26) {
        this.isImgDisabled = true
      }
      this.$emit('activeChanged', true)
      this.$emit('changeEditId', sectionId)
    },
    onImgChange(e) {
      if (e?.size < 5000000) {
        this.isImgDisabled = true
        this.$emit('saveTitle', e, this.section.id)
      } else {
        this.isImgDisabled = false
      }
    },
    clearInputImg(e) {
      if (e.target.value) {
        e.target.value = null
        this.inputImg = null
      }
    }
  }
}
</script>